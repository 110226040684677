<template>
    <div class="controls">
      <audio id="audioControl" autoplay loop controls>
        <source src="@/assets/audio/intro_loop.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
    </div>
    <router-link to="/en" class="wholePage">
      <div class="frame">
        <!-- <div class="img-layer img-layer--1"></div> -->
        <div class="img-layer img-layer--line-1"></div>
        <div class="img-layer img-layer--line-2"></div>
        <div class="img-layer img-layer--line-3"></div>
        <div class="img-layer img-layer--line-4"></div>
        <div id="image-flicker-1" class="img-layer"></div>
      </div>
    </router-link>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      latestAnimation: 0,
      animationClassNameBase: 'img-layer--image-flicker-',
      numberOfAnimations: 4,
      animationLoop: null,
      nextPageTimeout: null,
    };
  },
  methods: {
    changeAnimation() {
      let rnd = Math.floor(Math.random() * this.numberOfAnimations) + 1;
      while (rnd === this.latestAnimation) {
        rnd = Math.floor(Math.random() * this.numberOfAnimations);
      }
      const element = document.getElementById('image-flicker-1');
      if (this.latestAnimation) {
        element.classList.remove(`${this.animationClassNameBase}${this.latestAnimation}`);
      }

      element.classList.add(`${this.animationClassNameBase}${rnd}`);
      this.latestAnimation = rnd;
    },
  },
  mounted() {
    const audio = document.getElementById('audioControl');
    audio.volume = 0.25;
    this.animationLoop = setInterval(this.changeAnimation, 1500);
    this.nextPageTimeout = setTimeout(() => this.$router.push({ path: '/en' }), 15000);
  },
  beforeUnmount() {
    window.clearInterval(this.animationLoop);
    window.clearTimeout(this.nextPageTimeout);
  },
};
</script>

<style lang="scss" scoped>
@mixin flex($dir, $jus: flex-start, $ali: flex-start, $wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $jus;
  align-items: $ali;
  flex-wrap: $wrap;
}
$bg-img: '/images/logo/Begravd_index-logotyp.png';

#audioControl {
  width: 100px;
}

.controls {
  position: fixed;
  margin: auto;
  width: inherit;
  z-index: 2;
}

.wholePage {
  width: inherit;
  height: inherit;
}

.frame {
  width: inherit;
  height: inherit;
  position: relative;
  overflow: hidden;
  background-image: url('/images/backgrounds/background_ground.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.img-layer {
  width: inherit;
  height: inherit;
  background-image: url($bg-img);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;

  &--1 {
    // background-color: rgba(255,255,255,0.7);
    background-blend-mode: luminosity;
  }
  &--line-1 {
    background-color: rgba(255,255,255,0.5);
    background-blend-mode: overlay;
    animation:
      blinking 0.3s ease-out infinite,
      glitch-anim-horizontal 5s linear infinite alternate;
  }
  &--line-2 {
    background-color: rgba(255,255,255,0.08);
    background-blend-mode: overlay;
    animation:
      glitch-anim-horizontal 2s linear infinite alternate;
  }
  &--line-3 {
    background-color: rgba(255,255,255,0.02);
    background-blend-mode: overlay;
    animation:
      glitch-anim-horizontal 0.8s ease infinite alternate;
  }
  &--line-4 {
    background-color: rgba(255,255,255,0.2);
    background-blend-mode: overlay;
    clip-path: polygon(0 0, 0 1%, 100% 1%, 100% 0);
    animation:
      blinking 1s linear infinite,
      glitch-anim-vertical 3s ease infinite alternate;
  }
  &--image-flicker-1 {
    // background-color: rgba(255,255,255,1);
    background-blend-mode: luminosity;
    animation:
      blinking 0.8s linear infinite,
      glitch-anim-up-right 1s linear;
  }
  &--image-flicker-2 {
    // background-color: rgba(0,0,0,0.2);
    background-blend-mode: overlay;
    animation:
      blinking 0.8s 1.4s linear infinite,
      glitch-anim-down-left 1s linear;
  }
  &--image-flicker-3 {
    // background-color: rgba(255,255,255,1);
    background-blend-mode: luminosity;
    animation:
      blinking 0.8s linear infinite,
      glitch-anim-down-right 1s linear;
  }
  &--image-flicker-4 {
    // background-color: rgba(0,0,0,0.2);
    background-blend-mode: overlay;
    animation:
      blinking 0.8s 1.4s linear infinite,
      glitch-anim-up-left 1s linear;
  }
}

// animations
@keyframes blinking {
  0% { opacity: 0.25; }
  70%, 100% { opacity: 0; }
}

@keyframes glitch-anim-up-right {
  to { left: 8%; top: -7%; }
}

@keyframes glitch-anim-down-left {
  to { right: 8%; bottom: -5%; }
}

@keyframes glitch-anim-down-right {
  to { right: 8%; bottom: 3%; }
}

@keyframes glitch-anim-up-left {
  to { left: 8%; bottom: 1%; }
}

@keyframes glitch-anim-horizontal {
  0% {
    clip-path: polygon(0 0, 0 100%, 2% 100%, 2% 0);
  }
  10% {
    clip-path: polygon(35% 0, 35% 100%, 37% 100%, 37% 0);
  }
  25% {
    clip-path: polygon(25% 0, 25% 100%, 27% 100%, 27% 0);
  }
  40% {
    clip-path: polygon(35% 0, 35% 100%, 37% 100%, 37% 0);
  }
  50% {
    clip-path: polygon(75% 0, 75% 100%, 77% 100%, 77% 0);
  }
  60% {
    clip-path: polygon(65% 0, 65% 100%, 67% 100%, 67% 0);
  }
  75% {
    clip-path: polygon(35% 0, 35% 100%, 37% 100%, 37% 0);
  }
  85% {
    clip-path: polygon(85% 0, 85% 100%, 87% 100%, 87% 0);
  }
  100% {
    clip-path: polygon(100% 0, 100% 100%, 100% 100%, 100% 0);
  }
}

@keyframes glitch-anim-vertical {
  0% {
    clip-path: polygon(0 0, 0 1%, 100% 1%, 100% 0);
  }
  10% {
    clip-path: polygon(0 20%, 0 21%, 100% 21%, 100% 20%);
  }
  25% {
    clip-path: polygon(0 75%, 0 76%, 100% 76%, 100% 75%);
  }
  40% {
    clip-path: polygon(0 40%, 0 41%, 100% 41%, 100% 40%);
  }
  50% {
    clip-path: polygon(0 90%, 0 91%, 100% 91%, 100% 90%);
  }
  60% {
    clip-path: polygon(0 80%, 0 85%, 100% 85%, 100% 80%);
  }
  75% {
    clip-path: polygon(0 32%, 0 33%, 100% 33%, 100% 32%);
  }
  85% {
    clip-path: polygon(0 65%, 0 66%, 100% 66%, 100% 65%);
  }
  100% {
     clip-path: polygon(0 35%, 0 50%, 100% 50%, 100% 35%);
  }
}
</style>
